
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import mAxiosApi from "@/api";
  import * as moment from "moment";
  import CardN2f from "./CardN2f.vue";
  import N2FAddEdit from "./N2FAddEdit.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import SearchAndFilter from "@/views/still/search_and_filters.vue";
  import { useRouter } from "vue-router";
   
  export default defineComponent({
    name: "General",
    components : {
        CardN2f,
        N2FAddEdit,
        SearchAndFilter
    },
    setup() {

    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const disabled = ref(false)

    const state = reactive({
        mylist : {} as any,
        N2FAddEdit : 0,
        openCloseN2FAddEdit : 0,
        loading : true,
        initialMyList: {} as any,
        search : 0,
    });

    const tableFilters = ref([]) as any;

    const router = useRouter();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Note de frais", ["Pages", "Profile"]);

      

      tableFilters.value.push({ name: "Collaborateur", key: "emp_prenom_nom", label: "emp_prenom_nom" });
      tableFilters.value.push({ name: "Statut", key: "not_status", label: "not_status" });
      refresh();

      if (router.currentRoute.value.params.otherparam) {
        openN2F(Number(router.currentRoute.value.params.otherparam));
      } 
      state.loading = false;

    });
  

      const toDate = (d) => {
        return moment.default(d).format("DD.MM.YYYY");
      };


      const openN2F = (id = 0) =>{
        state.N2FAddEdit = id;
        state.openCloseN2FAddEdit = 1;
      }


      const SearchAndFilter = ref(null);


      const refresh = async (update = false, idN2f = 0) => {
        const getN2F = await mAxiosApi.prototype.getAxios("/getAllN2F");
        if (!update) {
          state.mylist = getN2F.results;
          state.initialMyList = state.mylist;
        } else {
          (SearchAndFilter.value as any).searchAllItems("", getN2F.results);
        }


      };
     
      return {
          disabled,
          dialogImageUrl,
          dialogVisible,
          state,
          toDate,
          openN2F,
          refresh,
          tableFilters,
          SearchAndFilter
      };
    },
  });
  