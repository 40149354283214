<template>
<transition name="fade">
  <div v-if="!state.loading" class="p-5 pt-0">

    <div class="d-flex flex-wrap flex-stack mb-5">

        <h2 class="fw-bold my-2 d-none text-uppercase text-dark">
          Note de frais des collaborateurs
        </h2>
      </div>
      <div class="card d-none d-md-block">
          <div class="card-header border-0 p-4 mb-5">
            <SearchAndFilter
                ref="SearchAndFilter"
                :listInitial="state.initialMyList"
                :filters="tableFilters"
                :key="state.initialMyList"
                classSearch="h-40px"
                widthDecalage="-300"
                @returnSearch="state.mylist = $event"
                classSelect="mx-1"
            ></SearchAndFilter> 
        </div>
      </div>

      <div class="row g-6 g-xl-9" v-if="state.mylist && state.mylist.length > 0">
        <div class="col-sm-6 col-xl-4 col-xxl-3" v-for="n2f in state.mylist" :key="n2f.not_seq">
          <div class="card h-100">
            <CardN2f
              card-classes="h-100 border-hover"
              :title="n2f.not_libelle"
              :content="`${n2f.montant_euro} €`"
              color="primary"
              :footer-data="n2f.not_status"
              :footer-text="n2f.emp_prenom_nom"
              arrow="Up-right"
              :indicator-value="`${n2f.tot_depense} dépense${n2f.tot_depense <= 1 ? '':'s'}`"
              :indicator-label="`Créee le ${toDate(n2f.note_date_creation)}`"
              @click="openN2F(n2f.not_seq)" 
            ></CardN2f>
          </div>
        </div>

      </div>

      <el-drawer v-model="state.openCloseN2FAddEdit" :with-header="false" size="900px" modal-class="modal-transp" :lock-scroll="false" :show-close="false">
        <N2FAddEdit apiName="getAllN2F" :idN2F="state.N2FAddEdit" :isOpen="state.openCloseN2FAddEdit" :key="state.openCloseN2FAddEdit" @refresh="refresh(true)" @closeN2FAddEdit="state.openCloseN2FAddEdit = 0"/>
      </el-drawer>

    </div>
  </transition>
</template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, reactive } from "vue";
  import mAxiosApi from "@/api";
  import * as moment from "moment";
  import CardN2f from "./CardN2f.vue";
  import N2FAddEdit from "./N2FAddEdit.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import SearchAndFilter from "@/views/still/search_and_filters.vue";
  import { useRouter } from "vue-router";
   
  export default defineComponent({
    name: "General",
    components : {
        CardN2f,
        N2FAddEdit,
        SearchAndFilter
    },
    setup() {

    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const disabled = ref(false)

    const state = reactive({
        mylist : {} as any,
        N2FAddEdit : 0,
        openCloseN2FAddEdit : 0,
        loading : true,
        initialMyList: {} as any,
        search : 0,
    });

    const tableFilters = ref([]) as any;

    const router = useRouter();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Note de frais", ["Pages", "Profile"]);

      

      tableFilters.value.push({ name: "Collaborateur", key: "emp_prenom_nom", label: "emp_prenom_nom" });
      tableFilters.value.push({ name: "Statut", key: "not_status", label: "not_status" });
      refresh();

      if (router.currentRoute.value.params.otherparam) {
        openN2F(Number(router.currentRoute.value.params.otherparam));
      } 
      state.loading = false;

    });
  

      const toDate = (d) => {
        return moment.default(d).format("DD.MM.YYYY");
      };


      const openN2F = (id = 0) =>{
        state.N2FAddEdit = id;
        state.openCloseN2FAddEdit = 1;
      }


      const SearchAndFilter = ref(null);


      const refresh = async (update = false, idN2f = 0) => {
        const getN2F = await mAxiosApi.prototype.getAxios("/getAllN2F");
        if (!update) {
          state.mylist = getN2F.results;
          state.initialMyList = state.mylist;
        } else {
          (SearchAndFilter.value as any).searchAllItems("", getN2F.results);
        }


      };
     
      return {
          disabled,
          dialogImageUrl,
          dialogVisible,
          state,
          toDate,
          openN2F,
          refresh,
          tableFilters,
          SearchAndFilter
      };
    },
  });
  </script>